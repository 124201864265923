.icon {
    position: relative;
    display: block;
    width: 200px;
    height: 200px;
    font-size: 1rem;
    float: left;
}

.icon .bar {
    display: inline-block;
    width: 25%;
    height: 100%;
    background: #1db954;
    transform-origin: bottom center;
}

@keyframes beat1 {
    from { transform: scaleY(.5); }
    10% { transform: scaleY(1); }
    50% { transform: scaleY(.6); }
    60% { transform: scaleY(.9); }
    to { transform: scaleY(.5); }
}

@keyframes beat2 {
    from { transform: scaleY(.4); }
    10% { transform: scaleY(.8); }
    48% { transform: scaleY(.3); }
    65% { transform: scaleY(.8); }
    to { transform: scaleY(.4); }
}

@keyframes beat3 {
    from { transform: scaleY(.3); }
    10% { transform: scaleY(.8); }
    55% { transform: scaleY(.2); }
    63% { transform: scaleY(.75); }
    to { transform: scaleY(.3); }
}

.icon .bar:nth-child(1) { animation: beat2 1.5s infinite linear; }
.icon .bar:nth-child(2) { animation: beat1 1.5s infinite linear; }
.icon .bar:nth-child(3) { animation: beat3 1.5s infinite linear; }
