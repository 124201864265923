html,
body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    @media(max-width: 800px) {
        font-size: 14px;
    }

    @media(max-width: 600px) {
        font-size: 12px;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
     margin-bottom: 1.5rem;
}

p {
    margin-bottom: 1.5rem;
}
