.collection {
    width: 100vw;
    height: 100vh;
}

.item {
    width: 100%;
    height: 100%;
    padding: 3rem;
    padding-bottom: 5rem;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
    align-items: center;
    justify-items: center;

    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @media (orientation: portrait) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 1.5rem;
    }
}

.column {
    width: 100%;

    @media (orientation: portrait) {
        height: 100%;
        width: auto;
        min-width: 500px;
    }

    @media (max-width: 500px) {
        min-width: 0;
    }
}

.about,
.main {
    grid-column: span 2;
    grid-row: span 2;
    width: 100%;
}
